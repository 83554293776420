body,
html {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

h1,
h2,
h3 {
    font-weight: bold;
}

.wrapper {
    min-height: 30em;
}

.center {
    display: flex;
    justify-content: center;
}

hr {
    opacity: 1;
    border-top: 2px solid #000;
}

.btn-dark {
    --bs-btn-bg: #000;
    --bs-btn-color: #ffe477;
    --bs-btn-hover-bg: #000;
    --bs-btn-hover-color: #ffb655;
    --bs-btn-active-bg: #000;
    --bs-btn-active-color: #ffb655;
}

.hero-container {
    background-image: linear-gradient(to right top, #dce6eb, #c8d2dc);
    min-height: 14em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    position: relative;
}

.hero-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: contain;
    font-size: 26px;
}

.hero-container-header {
    color: #006468;
    font-size: 38px;
}

.hero-container-column img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.social-contact-container {
    padding-top: 1em;
}

.cstm-link {
    color: #00969b;
}

.cstm-link:hover {
    color: #006468;
}

.card-group-centered {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card-group-centered .card {
    border: 2px solid #dee2e6;
    border-radius: 0.4rem;
}

.card-group-centered .card:first-child {
    border-radius: 0.4rem;
}

.card-group-centered .card:nth-child(2) {
    border-radius: 0.4rem;
    border-left: 2px solid #dee2e6;
}

@media only screen and (max-width: 720px) {

    .hero-container {
        min-height: 12em;
        padding-left: .1em;
    }

    .hero-container-column {
        font-size: 20px;
    }

    .hero-container-header {
        font-size: 32px;
    }

    .card-group-centered {
        justify-content: center;
        align-items: center;
    }

    .card-group-centered .card {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
}