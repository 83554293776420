/* NAV BAR */
.cstm-navbar {
    background-color: #000;
    font-size: larger;
}

.cstm-navbar-home {
    font-size: xx-large;
}

.nav-link {
    color: #ffe477;
}

.nav-link:focus,
.nav-link:hover,
.nav-link:active {
    color: #ffb655;
}

@media only screen and (max-width: 720px) {
    .cstm-navbar-home {
        font-size: x-large;
    }
}
