.footer {
    background-color: #000;
    margin-top: 40px;
    height: 60px;
    text-align: center;
    color: white !important;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
